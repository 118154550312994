import React, {useState} from 'react'
import {Link} from "gatsby";
import { StaticQuery, graphql } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Slider from '../what-we-do/slider';
import letterImg from "/src/assets/img/aboutUsFirstClass.png";
import letterImg1 from "/src/assets/img/aboutUsCertified.png";
import letterImg2 from "/src/assets/img/aboutUsCheck.png";
import letterImg3 from "/src/assets/img/aboutUsAdvanced.png";
import option1 from "./../../assets/img/certifiedLetters.png";
import option2 from "./../../assets/img/uspsTrackingIntegration.png";
import option3 from "./../../assets/img/dynamicTrackingPortal.png";
import option4 from "./../../assets/img/flexibleMailingOptions.png";

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

const IntroAboutUsComponent = (props) => {
  const slides = [
    {
      title: "First-Class Mail Services",
      description: `We specialize in sending confidential documents and important letters using our double-window, first-class envelopes. Our mailing services include trackability through USPS Informed Visibility, providing proof of mailing and scans throughout the delivery cycle.`,
      img: letterImg,
    },
    {
      title:
        "Certified and Priority Mail",
      description: `For added security and faster delivery, we offer Certified Mail with proof of delivery and Priority Mail services, perfect for time-sensitive documents or high-priority correspondence`,
      img: letterImg1,
    },
    {
      title: "Check Printing and Mailing Services",
      description: `Choose between our secure, self-contained pressure-sealed checks or traditional checks in envelopes, offering flexibility and professionalism for businesses needing to send checks for payroll, vendor payments, or other purposes. We make it easy to send a single check or thousands at a time. Additionally, we offer checks accompanied by letters, allowing you to deliver payments with important communication.
      `,
      img: letterImg2,
    },
    {
      title: "Advanced Tracking",
      description: `Every piece of mail or check sent through our platform can be tracked throughout its USPS journey. Our tracking portal gives you real-time updates, letter images, and mailing details, helping you stay informed and prepared with proof of delivery whenever needed.`,
      img: letterImg3,
    },
  ]; 

  const blocks = [
    {
      id: 1,
      title: "Envelope Options",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 16px; color: #000"><span style="font-weight: 600">Certified Mail #10 <br /> Envelopes <br /> </span> <span style="font-weight: 300; color: #484848; font-size: 14px; line-height: 16px;">Accommodates up to 5 physical pages, including the cover sheet.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 16px; color: #000"><span style="font-weight: 600">Certified Mail 9 x 12 Flat Envelopes</span>    <span style="font-weight: 300; color: #484848; font-size: 14px; line-height: 16px">Can hold up to 50 physical pages, including the cover sheet</span></div>
      </div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 2,
      title: "USPS Tracking Integration",
      description: `<div style="padding: 5px 5px; display: inline-block;">We are officially certified by the USPS to produce privately printed certified mail barcodes and tracking numbers. Using Intelligent Mail Package Barcodes (IMpb), we offer proof of induction into the USPS mail stream, with the first scan confirming the mail piece's placement into USPS custody. <br /> <br /> Our integration with USPS allows us to track each mail piece throughout the delivery cycle, providing multiple scan events, including the final delivery scan (if available).</div>`,
      imgSrc: option2,
      icon: reward,
    },
    {
      id: 3,
      title: "Dynamic Tracking Portal",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our tracking portal offers:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;">Real-time monitoring of letters and critical documents</li>
        <li style="padding: 0px 15px; font-size: 14px;">Viewing of letter images and Return Receipt Electronic (RRE) images</li>
        <li style="padding: 0px 15px; font-size: 14px;">Access to detailed tracking information</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">This becomes crucial when you need to show proof of mailing or delivery. Additionally, we provide mailing affidavit services for clients who require documented proof of mailing events.
      </div>`,
      imgSrc: option3,
      icon: monitor,
    },
    {
      id: 4,
      title: "Flexible Mailing Options",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Whether you're sending a single letter or a bulk mailing, our services are designed to accommodate your needs:</div> <br />
     <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> PDF Extraction Tool:</span> Extract recipient information seamlessly.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> Import from File Method:</span>  Easily import mailing lists from your files.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">Comprehensive API: </span>Integrate our services with your existing systems.</li>
    </ul>
 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">We store all USPS tracking event history, letter images, and mailing affidavit images (as applicable) for up to seven years, ensuring easy access through our interface or API.
      </div>`,
      imgSrc: option4,
      icon: letter,
    },
  ]

  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  const data = props.data;
  const texts = data.allTexts.edges[0].node
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  return (
    <div className="introAboutUsSlide">
      <div className="slideContent" style={{paddingBottom: '0'}}>
        {/* <div className="mainContainer gutter120">
          <h1 className='textCenter mb20'>{t(data.allDataJson.edges[0].node.aboutPage.title, texts)}</h1>
          <div className="titleTextHolder">
            {
              data.allDataJson.edges[0].node.aboutPage.text.map((item)=>{
                return (
                  <p key={item}>
                    {t(item, texts)}
                  </p>
                )
              })
            }
          </div>
          <div className="btnHolder centered mb120">
            <Link to="/contact-us" className="button" style={{width: '100%', maxWidth: '302px'}}>Contact Us</Link>
          </div>
        </div> */}
        <div className="mainContainer gutter60">
          <div className="aboutUsImgHolder mb90">
            <GatsbyImage image={getImage(data.allDataJson.edges[0].node.aboutPage.img)} alt='About us'/>
            <div className="aboutUsTitleWrapper">
              <h1 className='aboutUsTitle'>Your Premier Partner for Online Mailing Services</h1>
              <div className="btnHolder centered">
                <Link to="/contact-us/" className="button aboutUsTitleButton">Contact Us <div className="whiteDot"/></Link>
              </div>
            </div>
          </div>
          <div className="slideContent marginLeft">
            <div className="mainContainer whatWeDoCont paddingLeft ">
              <div className="aboutUsContBG"></div>
              <div className="whatWeDoTitleBlock">
                <div>
                  <div className="aboutUsSubTitle">
                  About UploadLetters.com
                  </div>
                </div>
                <div className="whatWeDoDescription">
                  At UploadLetters.com, we specialize in providing reliable, secure, and streamlined mailing and check printing solutions for individuals, businesses, and organizations of all sizes. Our platform is designed to simplify the process of sending important documents, letters, and payments, offering a range of services that cater to your specific needs—from First-Class Mail to Certified and Priority Mail, as well as professional check printing.
                </div>
              </div>
            </div>
          </div>
          <div className="slideContent marginLeft">
            <div className="mainContainer whatWeDoCont paddingLeft">
              <div className="whatWeDoTitleBlock">
                <div>
                  <div className="aboutUsSubTitle">
                    Who We Are
                  </div>
                </div>
                <div className="whatWeDoDescription">
                  We are technology and document output experts with a deep understanding of our clients’ needs within their respective industries. We pride ourselves on knowing and understanding the complex nuances of not only the mailing requirements but also the specific challenges within the sectors we serve. Whether you’re in finance, law, healthcare, or another industry, we apply our expertise to ensure that your mailing and document processing needs are met with precision and efficiency. Our solutions are designed to streamline your workflow while maintaining compliance with industry-specific regulations.
                </div>
              </div>
            </div>
          </div>
          <div class="textPrimary titlePrice mb30 text-center">What We Do</div>
          <Slider slides={slides}/>
          <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
            <div className="certifiedAnimationContBG"></div>
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                  Our Mail Offerings
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>
          {/* <div className="aboutStatHolder">
            <div className="aboutStat">
              <div className='textPrimary titlePrice mb40'>PARTNER WITH US</div>
              <div className="aboutStatDescription mb40">
                {
                  data.allDataJson.edges[0].node.aboutPage.text.map((item)=>{
                    return (
                      <>
                        <p key={item}>
                          {t(item, texts)}
                        </p>
                        <br />
                      </>
                    )
                  })
                }
              </div>
              <ul className="aboutStatList">
                {
                  data.allDataJson.edges[0].node.aboutPage.statList.map((item)=>{
                    return (
                      <li className="aulItem" key={item.qnt}>
                        <h3 className="qnt" style={{marginBottom: '12px'}}>{t(item.qnt, texts)}</h3>
                        <div className="aboutStatListCaption">{t(item.caption, texts)}</div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div> */}
          <div className="slideContent marginLeft">
            <div className="mainContainer whatWeDoCont paddingLeft">
            <div className="aboutUsContBG" style={{height: "568px", width: "1250px"}}></div>
              <div className="whatWeDoTitleBlock">
                <div>
                  <div className="aboutUsSubTitle">
                    Our Mission
                  </div>
                </div>
                <div className="whatWeDoDescription">
                  Our mission is to provide efficient, secure, and reliable mailing and check printing services for individuals and businesses, tailored to the specific needs of their industries. We believe that sending important communications and payments should be easy, affordable, and worry-free. By combining our expertise in technology and document output with a deep understanding of our clients’ industry verticals, we ensure that our solutions not only meet, but exceed expectations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

const IntroAboutUsComponentQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allDataJson {
          edges {
            node {
              aboutPage {
                button
                statList {
                  caption
                  qnt
                }
                text
                title
                img {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        allTexts {
          edges {
            node {
              aboutPage_button
              aboutPage_statList_0_caption
              aboutPage_statList_0_qnt
              aboutPage_statList_1_caption
              aboutPage_statList_1_qnt
              aboutPage_statList_2_caption
              aboutPage_statList_2_qnt
              aboutPage_statList_3_caption
              aboutPage_statList_3_qnt
              aboutPage_text1
              aboutPage_text2
              aboutPage_title
            }
          }
        }
      }
    `}
    render={(data) => <IntroAboutUsComponent data={data} {...props}/>}
  />

)

export default IntroAboutUsComponentQuery