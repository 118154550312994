import React, { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import hero from "../assets/img/checkMailHero.png"
import option1 from "../assets/img/addressVerifEnhancedAddress.png"
import option2 from "../assets/img/addressVerifDynamicTracking.png"
import option3 from "../assets/img/addressVerifIntegration.png"
import option4 from "../assets/img/flexibleMailingOptions.png"
import ABanner from "../components/features/ab.component"

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

import dynamic from "/src/assets/img/dynamic.svg"
import competetive from "/src/assets/img/technology.svg"
import service from "/src/assets/img/next-day-service.svg"
import secure from "/src/assets/img/secure.svg"
import legal from "/src/assets/img/legal.svg"
import business from "/src/assets/img/business.svg"
import government from "/src/assets/img/goverment.svg"
import individuals from "/src/assets/img/individuals.svg"

const AddressVerification = ({ location }) => {
  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const blocks = [
    {
      id: 1,
      title: "Enhanced Address Validation:",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our tracking portal offers:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText">Comprehensive Move Database: Beyond basic address validation, our service can cross-reference recipient names with a move database that tracks address changes dating back 17 years. This extensive coverage surpasses the USPS NCOALink® service, which only covers the past 4 years.</li>
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText">Detailed Classification: Addresses are categorized as "good" if they meet validation standards or "unknown" if they cannot be validated, allowing you to take appropriate action.</li>
      </ul>`,
      imgSrc: option1,
      icon: monitor,
    },
    {
      id: 2,
      title: "Dynamic Tracking Portal",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our tracking portal provides:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText">Real-time monitoring of the verification process</li>
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText">Access to detailed verification results </li> 
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText">Easy download of validated address lists</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;" class="carouselText">Our portal ensures that you have full visibility into the accuracy of your mailing lists, helping you maintain up-to-date and USPS-compliant addresses.
      </div>`,
      imgSrc: option2,
      icon: monitor,
    },
    {
      id: 3,
      title: "Flexible Integration Options",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Whether you prefer using our intuitive UI or need a more automated approach, our Address Verification service offers flexibility to meet your needs:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText"><span style="font-weight: 600"> UI for Simple Uploads: </span>Quickly upload and verify addresses with our easy-to-use interface.</li>
        <li style="padding: 0px 15px; font-size: 14px;" class="carouselText"><span style="font-weight: 600"> API for Seamless Workflow Integration: </span>Integrate address verification directly into your existing systems for real-time validation.</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;" class="carouselText">All verification event history and output files are stored securely in our system, accessible through our interface or API for up to seven years, providing you with a comprehensive and reliable address management solution.
      </div>`,
      imgSrc: option3,
      icon: monitor,
    },
  ]

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="introSlide">
          <div className="introSlideCertified">
            <div className="slideContent">
              <div className="whatIsCont gutter120">
                  <div className="certifiedsubTitle">
                    Address Verification
                  </div>
                  <h1>
                    Ensure Accuracy, Save Time, <br /> and Reduce Costs in Your <br /> 
                     Mailing Process
                  </h1>
                  <p className="certifiedTitleDesription">
                  At UploadLetters.com, our Address Verification service is designed to streamline your mailing process by ensuring the accuracy and validity of recipient addresses. Whether you are sending a single letter or managing bulk mailings, our service provides the reliability and efficiency you need to reach your intended recipients.
                  </p>
                  <div className="btnHolder getStartedWrapper">
                    <a
                      href="https://app.uploadletters.com/register"
                      className="button intoSlideButton getStarted"
                    >
                      <div className="intoSlideButtonText">Get Started</div>
                    </a>
                  </div>
                  {/* <img src={hero} alt="hero"  className="certifiedHero"/> */}
              </div>
            </div>
          </div>
        </div>

        <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className=" whatIsContMain ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDocertifiedsubTitle">
                  What is Address Verification?
                  </h1>
                </div>
                <div className="whatWeDoDescription">
                Address Verification is a critical service that checks and corrects recipient addresses to ensure they meet USPS CASS™ (Coding Accuracy Support System) standards. By validating addresses before mailing, you reduce the risk of undeliverable mail, save on postage costs, and improve overall mailing efficiency.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="whoUsesCont" style={{ background: "#f2f3fc" }}>
          <h1 className="whatIsTitle">Who Uses Address Verification?</h1>
          <div className="whoUsesContBlock">
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Businesses</div>
                <img src={legal} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Ensure invoices, statements, and marketing materials reach the correct addresses.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Government Agencies</div>
                <img src={business} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Validate addresses for official notices, voter information, and tax notifications.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Educational Institutions</div>
                <img src={government} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Verify addresses for admissions letters, transcripts, and alumni communications.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Individuals</div>
                <img src={individuals} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Confirm address accuracy for personal correspondence, gifts, or legal documents.
              </div>
            </div>
          </div>
        </div>

        <div className="introSlideHowToGet">
          <div className="slideContent maxWidth">
            <div className="mainContainer gutter120">
              <div className="textPrimary titlePrice mb30">
              Our Address Verification Offerings
              </div>
              <div className="uspsServiceBlock">
                <div className="uspsServiceBlockTitle">User Interface (UI) Access:</div>
                <ul>
                <li className="uspsServiceBlockDescr uspsDescrPadding">
                Easy Upload: Effortlessly upload a CSV file containing recipient names and/or addresses to our user-friendly interface.
                </li>
                <li className="uspsServiceBlockDescr uspsDescrPadding">
                Quick Turnaround: Within minutes, our system processes the data, verifying and correcting addresses according to USPS CASS™ standards.
                </li>
                <li className="uspsServiceBlockDescr uspsDescrPadding">
                Downloadable Results: Once processed, an output file is generated, indicating which addresses have been validated and formatted correctly ("good") and which could not be validated ("unknown"). This file is available for download directly from the UI.
                </li>
                </ul>
              </div>
              <div className="uspsServiceBlock">
                <div className="uspsServiceBlockTitle">API Access:</div>
                <ul>
                <li className="uspsServiceBlockDescr uspsDescrPadding">
                Seamless Integration: For users who prefer automation, our API allows you to integrate address verification directly into your application or workflow, validating addresses in real-time as part of your letter request orders.
                </li>
                <li className="uspsServiceBlockDescr uspsDescrPadding">
                In-Line Validation: Addresses are validated and corrected as they are submitted, ensuring accuracy before letters are mailed.
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
       
        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
            <div className="certifiedAnimationContBG"></div>
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                Advanced Features
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
                <div
                className={`imageContainer imContAddress ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mainContainer whatIsCont"
          style={{ background: "#f2f3fc" }}
        >
          <h2 className="whatIsTitle">Why Choose Us?</h2>
          {/* <div className="whatIsSubTitle">Whether you're paying vendors, managing payroll, or disbursing payments for larger applications, our check printing and mailing services provide a reliable, professional, and cost-effective solution.</div> */}
          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Accurate and Up-to-Date Addresses:</span> <br />
                Ensure your mail reaches the right recipients with verified and corrected addresses.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={competetive} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Cost-Effective Solutions:</span>{" "}
                <br />
                Reduce costs associated with undeliverable mail by using our advanced verification tools.
              </div>
            </div>
          </div>

          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={service} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Comprehensive Coverage:</span> <br />
                Benefit from enhanced address validation, including access to a comprehensive move database.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={secure} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">
                Flexible Integration:
                </span>{" "}
                <br />
                Choose the method that works best for your business, whether through our user interface or seamless API integration.
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer gutter60">
        <h1 className="bannerTitle">At UploadLetters.com, our Address Verification service is an essential tool for ensuring your mail reaches its intended recipients efficiently.
        </h1>
        <div className="bannerSubtitle">Contact us today to learn more about how our verification tools can help you save time, reduce costs, and improve your mailing process.</div>
          <ABanner />
        </div>
      </MainLayout>
    </>
  )
}

AddressVerification.defaultProps = {
  location: {},
}

export default AddressVerification

export const Head = () => (
  <Seo
    title="Reliable Address Verification Services"
    description="Ensure the accuracy of your customers' addresses with our comprehensive address verification service. Improve delivery rates, reduce returned mail, and maintain up-to-date customer data effortlessly."
  />
)
